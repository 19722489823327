import React from 'react'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Report from './routes/report/Report'

export default function Root() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/accounts/:accountId/analytics" component={Report} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}
