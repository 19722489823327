import React, { useEffect, useState } from 'react'
import { useApiGet, useTitle } from '@papertrail/web3-utils'
import { useSessionUser } from '@papertrail/web3-session'
import { Select, MenuItem, FlexRow, Padding, Box } from '@papertrail/styleguide'
import { useParams } from 'react-router-dom'

const styles = {
  reportContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column'
  },
  report: {
    border: 'none',
    height: '100%',
    width: '100%'
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100'
  },
  header: {
    borderBottom: '1px solid #ddd'
  },
  logo: {
    height: '40px',
    width: '40px',
    marginRight: '3px'
  },
  select: {
    width: '200px',
    height: '45px'
  }
}

function mapToken(data) {
  return data.data.token
}

function mapRecord(data) {
  return data.data.map((d) => ({
    id: d.id,
    name: d.name,
    key: d.key,
    url: d.embed_report_url
  }))
}

export default function Report() {
  const user = useSessionUser()

  const { accountId } = useParams() as {
    accountId: string
  }

  // test using accountId ba4b9107-4624-4196-a2b1-59e54a2fb8db on production
  const [tokenState, getToken] = useApiGet<string>(`/accounts/${accountId}/token`, mapToken)
  const [reportsState, getReportState] = useApiGet(`/accounts/${accountId}/analytic/reports`, (data) => mapRecord(data))

  const [report, setReport] = useState('')

  useTitle('Analytics')

  useEffect(() => {
    if (user) {
      getToken('')
      getReportState('')
    }
  }, [user])

  useEffect(() => {
    if (reportsState.isLoaded && reportsState.data.length >= 1) {
      setReport(reportsState.data[0].url)
    }
  }, [reportsState])

  const userId = user ? user.id : null
  const token = tokenState.isLoaded ? tokenState.data : null

  function onSelectChange(report) {
    setReport(report)
  }

  function getIframeUrl() {
    const params: any = {}

    for (let i = 1; i <= 5; i++) {
      params[`token_${i}`] = token
      params[`account_id_${i}`] = accountId
      params[`user_id_${i}`] = userId
      // params[`token_expiry_${i}`] = getTimestamp(30)
    }

    const encodedParams = encodeURIComponent(JSON.stringify(params))

    const url = `${report}?params=${encodedParams}`
    return url
  }

  return (
    <>
      {tokenState.isLoading && <Padding>Please wait...</Padding>}

      {tokenState.isError && (
        <Padding>An error occurred while trying to load data. Make sure the account exists.</Padding>
      )}

      {reportsState.isLoaded && (
        <Box display={'flex'} flexDirection={'column'} height={'100%'}>
          <FlexRow justifyContent={'space-between'} alignItems="center" padding={2} sx={styles.header}>
            {reportsState.data.length > 1 && (
              <FlexRow justifyContent="start">
                &nbsp;
                <Select
                  data-testid="report-select"
                  value={report}
                  onChange={(e) => onSelectChange(e.target.value)}
                  sx={styles.select}>
                  {reportsState.data.map((option) => (
                    <MenuItem key={option.key} value={option.url} data-testid={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FlexRow>
            )}
          </FlexRow>

          {tokenState.isLoaded && report !== '' && (
            <iframe title="report" data-testid="report-iframe" src={getIframeUrl()} style={styles.report} />
          )}

          {tokenState.isLoaded && report === '' && <div style={styles.message}>Please select a report.</div>}
        </Box>
      )}
    </>
  )
}
